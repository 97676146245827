<template>
  <div class="home" style="height: 100%">
    <v-container style="height: 70%" align-center justify-center>
      <v-row>
        <v-col cols="3" />
        <v-col cols="6"
          ><strong>{{ $t("vehicle.title") }}</strong></v-col
        >
        <v-col cols="3" />
      </v-row>
      <v-row>
        <v-col cols="2" />
        <v-col cols="3">{{ $t("vehicle.vehicleType") }}</v-col>
        <v-col cols="5"
          >{{ $store.state.vehicleBrand }} /
          {{ $store.state.vehicleModel }}</v-col
        >
        <v-col cols="2" />
      </v-row>
      <v-row>
        <v-col cols="2" />
        <v-col cols="3">{{ $t("vehicle.vehicleImmat") }}</v-col>
        <v-col cols="5">{{ $store.state.vehicleImmat }}</v-col>
        <v-col cols="2" />
      </v-row>
      <v-row>
        <v-col cols="2" />
        <v-col cols="3">{{ $t("vehicle.vehicleVIN") }}</v-col>
        <v-col cols="5">{{ $store.state.vehicleVIN }}</v-col>
        <v-col cols="2" />
      </v-row>
      <v-row>
        <v-col cols="2" />
        <v-col cols="3" style="padding: 30px 12px 0 12px">{{
          $t("vehicle.vehicleMileage")
        }}</v-col>
        <v-col cols="4">
          <v-text-field
            v-model="mileage"
            :label="$t('vehicle.vehicleMileage')"
            :readonly="!editMileage"
            :filled="!editMileage"
            style="height: 70%"
          ></v-text-field> </v-col
        ><v-col cols="1"
          ><v-btn
            style="height: 70%"
            @click="
              editMileage = !editMileage;
              input = mileage;
              commitMileage();
            "
            ><v-icon v-if="!editMileage">mdi-pencil</v-icon
            ><v-icon v-else>mdi-check</v-icon></v-btn
          ></v-col
        >

        <v-col cols="2" />
      </v-row>
      <v-row>
        <v-col cols="2" />
        <v-col cols="1"
          ><v-switch
            v-model="cgvAccepted"
            @click="toggleCgv"
            @change="toggleCgv"
        /></v-col>
        <v-col cols="5" style="padding: 30px 0 0 0">{{
          $t("vehicle.cgv")
        }}</v-col>
        <v-col cols="3">
          <v-dialog v-model="dialog" width="90%">
            <template #activator="{ on, attrs }">
              <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
                {{ $t("vehicle.readCGV") }}
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="headline grey lighten-2"> CGV </v-card-title>
              <!-- TODO: issue #131 -->
              <v-card-text>
                MENTIONS LEGALES ET CONDITIONS GENERALES D’UTILISATION DU
                SERVICE 24/7, CG24/7<br />
                DEFINITIONS<br />
                « Borne » : Désigne la borne digitale implantée sur la Zone de
                Réception du PDV, qui constitue le moyen par l’intermédiaire
                duquel le Service est exécuté. La borne permet notamment d’une
                part le dépôt des clés et du certificat d’immatriculation du
                véhicule et d’autre part la restitution des clés et du
                certificat d’immatriculation du véhicule.<br />
                « Client(s) » : Désigne la personne physique majeure ou personne
                morale qui souhaite utiliser tout ou partie du Service dans le
                cadre de l’exécution des Prestations.<br />
                « Commande de travaux »: Désigne le document associé à ses
                propres Conditions Générales de Réparation (CGR) qui après la
                réception du véhicule par le PDV est renseigné par le PDV de
                façon à être remis ou adressé par mail au Client. La Commande de
                Travaux comporte l’état apparent du véhicule constaté lors de la
                réception, le détail des travaux à réaliser avec leur chiffrage
                (estimation/devis). Il est bien précisé que lorsque le terme «
                Commande de Travaux » est utilisé dans les présentes CG24/7, il
                comprend les CGR de ladite Commande de Travaux. Lorsque la
                commande de travaux est envoyée au client dans le cadre du
                Service, elle doit être accompagnée de la Déclaration de
                Confidentialité.<br />
                « Conditions Générales 24/7 » ou « CG24/7 »: Désigne le présent
                document qui vient compléter, selon le(s) cas, les Conditions
                Générales de la commande de Travaux et/ou les conditions
                d’utilisation du Site (=Mentions légales), dès lors que le
                Client souhaite bénéficier de tout ou partie du Service dans le
                cadre de l’exécution des Prestations.<br />
                « Contrat » : Désigne la commande de Commande de Travaux (en ce
                compris ses CGR) associé aux présentes Conditions Générales 24/7
                et la confirmation du Client de son acceptation des CG24/7.<br />
                « Déclaration de confidentialité » : Désigne le document
                accompagnant la Commande de Travaux, par lequel le client est
                informé du statut de ses données personnelles.<br />
                « Point de vente » ou « PDV »: Désigne le membre du réseau agréé
                de l’une et/ou des entités du Groupe PSA, pour la marque
                identifiée qui peut être, selon le cas, Peugeot ou Citroën, ou
                DS, ou Opel (dénommée la « Marque PSA ») qui propose le Service
                dans le cadre de l’exécution des Prestations. Il est rappelé
                conformément aux termes de la Commande de Travaux que le PDV est
                responsable vis- à-vis du Client, des engagements de toute
                nature pris par lui, nonobstant la responsabilité légale du
                constructeur du véhicule.<br />
                « Prestation(s) »: Désigne toute prestation de travaux, sur un
                véhicule, commandée via le Site Internet ou par téléphone ou en
                PDV, qui peut être associée au Service.<br />
                « Service(s) » ou « Service 24/7 »: Désigne le service gratuit
                dit de Service 24 / 7 proposé aux Clients dans le cadre de
                l’exécution de Prestations et/ou de la prise de rendez-vous en
                ligne pour l’exécution des Prestations. Le Service consiste en
                la possibilité pour le Client, de déposer et/ou de récupérer son
                véhicule, dans le cadre de Prestations, via la Borne, en dehors
                des horaires d’ouverture du PDV, dans les conditions qui sont
                décrites dans les présentes CG24/7.<br />
                « Site Internet » ou « Site »: Désigne le Site Internet du PDV
                par l’intermédiaire duquel il est possible de prendre
                rendez-vous en ligne, dans le PDV, pour l’exécution des
                Prestations en y associant, si le Client le souhaite, tout ou
                partie du Service. Il est précisé que le Client peut utiliser le
                Service sans forcément recourir au Site Internet du PDV.<br />
                « Zone de Réception »: Désigne une zone implantée au sein du PDV
                réservée à l’accueil des Clients, dans laquelle est localisée la
                Borne et les emplacements pour garer les véhicules. Il est bien
                précisé que la Zone de Réception est privée (sans forcément être
                fermée au public) et, accessible dans tous les cas, aux Clients
                en dehors des horaires d’ouverture du PDV.<br />
                <br />
                ARTICLE 1 - OBJET<br />
                1.1 - Les Conditions Générales Self Réception définissent les
                conditions d’exécution du Service lors<br />
                du dépôt et/ou du retrait d’un véhicule pour l’exécution des
                Prestations.<br />
                Dans ce cadre, il est précisé que les Prestations sont soumises
                à la Commande de Travaux.<br />
                1.2 - Le recours au Service implique une acceptation préalable
                et sans réserve par le Client du Contrat, composé notamment des
                présentes CG24/7, associées à la Commande de Travaux, seules
                applicables, à l’exclusion de toute autre.<br />
                1.3 - Il est bien précisé que l’objectif du Service est d’offrir
                plus de flexibilité au Client quant à la gestion de ses
                rendez-vous, en PDV, pour les Prestations. Le Client est
                néanmoins bien informé que son véhicule ne sera sous la garde du
                PDV qu’à partir du moment où ce dernier :<br />
                aura récupéré les clés du véhicule dans la Borne ;<br />
                et<br />
                n’aura pas constaté de défaut lors de la prise en charge du
                véhicule, conformément aux termes de l’article 2.2.3
                ci-après.<br />
                1.4 - Les CG24/7 sont consultables sur le Site et la Borne. En
                revanche, elles ne peuvent être imprimées, par le Client, qu’à
                partir du Site Internet.<br />
                1.5 - Il appartient au Client de se connecter à ses frais et
                sous sa seule responsabilité au réseau internet pour pouvoir
                accéder au Site Internet et, le cas échéant, à ses emails. Le
                Client est seul responsable du bon fonctionnement de son
                équipement informatique et de son accès internet.<br />
                ARTICLE 2 - DESCRIPTION DU PROCESSUS DE COMMANDE DES PRESTATIONS
                ASSOCIÉES AU SERVICE<br />
                2.1 - Service 24 / 7 – Conditions<br />
                2.1.1 – Conditions liées au PDV<br />
                Seuls les PDV équipés de Bornes et d’une Zone de Réception,
                peuvent être en mesure d’exécuter les Prestations associées au
                Service.<br />
                Dès lors qu’un PDV propose le Service, les conditions décrites
                ci-dessous seront applicables et viendront compléter la Commande
                de Travaux, pour le cas où le Client aurait manifesté le souhait
                d’utiliser tout ou partie du Service.<br />
                2.1.2 - Conditions liées au Client<br />
                Le Service n’est accessible qu’aux Clients disposant d’une
                adresse internet et d’un téléphone mobile.<br />
                2.2 - Processus – Parcours Client<br />
                Le Service peut être proposé pour :<br />
                le dépôt du véhicule, lors de la prise de rendez-vous pour les
                Prestations et/ou<br />
                la restitution du véhicule à l’issue des Prestations.<br />
                Le Client peut décider d’utiliser tout ou partie du Service.<br />
                Les différentes étapes du parcours du Client pour l’exécution
                des Prestations associées au Service sont :<br />
                <br />
                la prise de rendez-vous,<br />
                le dépôt du véhicule en PDV,<br />
                l’avis de confirmation de réception des clés du véhicule et, le
                cas échéant, du certificat d’immatriculation, par SMS,<br />
                la réception du véhicule par le PDV (prise en charge et/ou vidéo
                check) - Validation du chiffrage initial ou proposition de devis
                de travaux complémentaires,<br />
                l’avis de fin de travaux et facturation,<br />
                le paiement en ligne à partir du lien url adressé au Client,<br />
                la récupération du véhicule<br />
                Le Client sera considéré comme ayant utilisé :<br />
                l’intégralité du Service, lorsqu’il aura utilisé le Service du
                dépôt de son véhicule à sa récupération via la Borne.<br />
                en partie le Service, lorsqu’il aura déposé ou récupéré son
                véhicule via la Borne.<br />
                2.2.1 – Prise de rendez-vous<br />
                Lors de la prise de rendez-vous pour les Prestations, que ce
                soit en PDV, par téléphone ou sur le Site internet, le Client
                est informé qu’il peut utiliser tout ou partie du Service.<br />
                Dans tous les cas où le Client souhaiterait bénéficier du
                Service, il doit communiquer au PDV ou renseigner sur le Site,
                les informations suivantes, qui sont indispensables tant à
                l’exécution de la Prestation que du Service :<br />
                Nom, prénom du Client,<br />
                numéro de téléphone portable,<br />
                adresse email,<br />
                modèle du véhicule,<br />
                numéro d’immatriculation ou numéro VIN du véhicule, prestation
                demandée,<br />
                kilométrage du véhicule<br />
                Le Client peut manifester son intérêt pour le Service :<br />
                soit directement au PDV, par oral, ou par téléphone,<br />
                soit indirectement, en cochant la case correspondante sur le
                Site Internet.<br />
                Dès lors que le Client a manifesté son intérêt pour le Service,
                le Client reçoit, dans un délai de 72 heures maximum, via un
                message SMS sur le numéro de téléphone portable renseigné :<br />
                la confirmation de son rendez-vous, associé à un lien vers les
                présentes CGSR, et<br />
                un code à 6 (six) chiffres, sous réserve que la Prestation
                puisse être associée au Service.<br />
                Lorsque la Prestation demandée le requiert, il peut être demandé
                au Client de remettre le certificat d’immatriculation original
                du véhicule avec les clés de ce dernier.<br />
                Dans ce cadre, il est précisé que :<br />
                la remise de l’original du certificat d’immatriculation du
                véhicule dans la Borne est notamment requise, par le PDV, pour
                toute Prestation consistant en un contrôle technique, une
                expertise assurance ...et/ou dès lors que la Prestation concerne
                tout véhicule d’une autre marque que les marques suivantes du
                Groupe PSA (Peugeot, Citroën, DS et Opel).<br />
                le code dit de dépôt du véhicule est indispensable au Client
                pour pouvoir bénéficier du Service dans le PDV du lieu du
                rendez-vous.<br />
                2.2.2- Dépôt du véhicule en PDV<br />
                Une fois que le Client a obtenu son rendez-vous et son code de
                dépôt à 6 chiffres, conformément aux termes de l’article 2.2.1
                ci-dessus, ce dernier peut déposer son véhicule dans les
                conditions suivantes :<br />
                <br />
                Jusqu’à 72 heures avant le jour et l’heure du rendez-vous dans
                le PDV ; Il est bien entendu que ce délai constitue un
                maximum.<br />
                garer correctement le véhicule sur Zone de Réception du PDV,
                identifiée et située à proximité de la Borne;<br />
                s’assurer ne pas avoir laissé d’effets personnels dans le
                véhicule et avoir bien verrouillé ce dernier ; Enregistrer le
                dépôt de son véhicule sur la Borne en saisissant le code de
                dépôt à 6 chiffres et en suivant toutes les instructions
                figurant sur l’écran de la Borne pour déposer :<br />
                La clé de son véhicule, en s’assurant au préalable avoir retiré
                toutes autres clés de tout porte-clés éventuel;<br />
                et l’original du certificat d’immatriculation du véhicule, si
                requis par le PDV, comme indiqué dans le SMS de confirmation du
                rendez-vous.<br />
                En cas d’oubli du code de dépôt, la Borne donne la possibilité
                au Client de le récupérer, après avoir renseigné, le code VIN ou
                le numéro d’immatriculation du véhicule, dans le champ de la
                Borne réservé à cet effet. Le code de dépôt est alors adressé
                via sms sur le numéro de téléphone mobile renseigné par le
                Client.<br />
                La Borne affiche la synthèse du rendez-vous et de la Prestation
                commandée en donnant la possibilité au Client de :<br />
                confirmer et/ou de corriger et/ou compléter toutes informations
                communiquées lors du rendez-vous, conformément aux termes de
                l’article 2.2.1 ci-dessus;<br />
                formuler un commentaire et/ou apporter des précisions, pour
                notamment signaler tout défaut sur la carrosserie du véhicule
                (bosse, rayure etc...) de façon à faciliter la prise en charge
                par le PDV du véhicule;<br />
                accepter les CG24/7, pour permettre à la Borne de se verrouiller
                sur les clés et le certificat d’immatriculation du véhicule, si
                ce dernier est requis, pour l’exécution des Prestations.<br />
                Le Client est informé qu’une photographie sera prise par la
                Borne des éléments déposés (clé et certificat d’immatriculation,
                si ce dernier est requis pour la Prestation), au sein de cette
                dernière.<br />
                Un SMS de confirmation de la réception de la clé du véhicule et
                du certificat d’immatriculation, si la remise de ce dernier est
                requise pour la Prestation, est alors adressé au Client, au
                numéro de téléphone portable renseigné par le Client.<br />
                A partir de l’envoi de ce SMS, le PDV est considéré comme le
                gardien des clés du véhicule, étant précisé que la garde du
                véhicule n’incombe au PDV qu’à compter de la réception du
                véhicule telle que définie dans l’article 2.2 ci-après.<br />
                2.2.3 - Réception du véhicule par le PDV (prise en charge et, le
                cas échéant, vidéo check) et validation du chiffrage initial ou
                proposition de devis de travaux complémentaire<br />
                A chaque période d’ouverture du PDV, ce dernier procède, sur les
                Zones de Self Réception, sans déplacer les véhicules, à un tour
                des véhicules dont les clés, voire le cas échéant le certificat
                d’immatriculation, ont été remis en Borne.<br />
                Ce tour des véhicules consiste en un contrôle du kilométrage
                déclaré par le Client, ainsi qu’un contrôle visuel de l’état
                apparent du véhicule, conformément aux termes de la Commande de
                Travaux (contrôles visuels en réception et article Réception des
                CGR).<br />
                A cette occasion, si le PDV constate que le véhicule :<br />
                (i) ne présente pas de défauts autres que ceux éventuellement
                signalés par le Client ; le PDV renseigne la Commande de
                Travaux.<br />
                ou<br />
                (ii) présente des défauts qui n’auraient pas été signalés par le
                Client, alors le PDV les renseigne dans la Commande de Travaux,
                ou bien effectue alors une vidéo check de l’état du véhicule.
                Par cette vidéo check, le PDV filme l’état apparent du véhicule,
                constaté lors du tour de ce dernier afin d’enregistrer toute
                réserve quant à l’état du véhicule. Le lien pour<br />
                <br />
                accéder au résultat de cette vidéo est adressé au Client en même
                temps que la Commande de Travaux.<br />
                Dans tous les cas, le PDV renseigne la Commande de Travaux,
                laquelle comprend :<br />
                l’état constaté du véhicule,<br />
                la confirmation de l’estimation/devis de la Prestation commandée
                ou, le cas échéant, établit un devis complémentaire, si l’état
                du véhicule le requiert.<br />
                Il est bien précisé que le PDV se réserve le droit de refuser de
                prendre en charge un véhicule s’il juge l’état du véhicule non
                conforme à une utilisation normale faite par son propriétaire ou
                si les dégâts constatés ne sont pas de nature à être pris en
                charge par le PDV.<br />
                Le PDV envoie au Client, à l’adresse email indiquée par ce
                dernier, la Commande de Travaux, accompagnée de la Déclaration
                de confidentialité. La Commande de Travaux envoyée au client
                précise notamment, outre l’état constaté du véhicule, le délai
                pour exécuter les Prestations et le délai imparti au Client pour
                donner son l’accord pour exécution des Prestations, ainsi que la
                durée des Prestations.<br />
                Dans le délai qui lui est imparti, le Client doit adresser au
                PDV son accord sur la Commande de Travaux de préférence par
                retour de mail et/ou le cas échéant par SMS.<br />
                Si des travaux complémentaires ont été proposés au Client, ce
                dernier doit préciser au PDV, s’il :<br />
                a. accepte le devis complémentaire en sus des Prestations
                demandées, lors de la prise de<br />
                rendez-vous ; ou<br />
                b. souhaite que seules les Prestations demandées lors de la
                prise de rendez-vous, soient exécutées.<br />
                Le défaut de réponse du Client, dans le délai imparti précité,
                sur les travaux complémentaires, vaut refus du Client, sous sa
                seule et exclusive responsabilité Conformément aux termes des
                CGR, le PDV est dégagé de toute responsabilité si le Client
                refuse la réalisation des travaux complémentaires. Dans un tel
                cas, seules les Prestations faisant l’objet du devis initial
                seront exécutées par le PDV.<br />
                Il est bien entendu que dans le cas où le Client ne donnerait
                pas son accord dans le délai indiqué sur la Commande de Travaux,
                le PDV se réserve la possibilité de revoir le délai d’exécution
                des Prestations, proposé lors de la prise de rendez-vous.<br />
                Il est bien précisé que c’est l’acceptation expresse, par le
                Client, de la Commande de Travaux qui marque le point de départ
                de la garde du véhicule et de l’application pleine et entière
                des dispositions de la Commande de Travaux. Autrement dit, le
                Client reste responsable de son véhicule tant qu’il n’a pas
                accepté la Commande de Travaux.<br />
                A défaut d’accord du Client sur l’état du véhicule et/ou des
                Prestations et/ou de la Commande de Travaux, il est bien entendu
                que le PDV ne déplacera pas et n’exécutera pas de Prestations
                sur le véhicule.<br />
                Le Client, qui renoncerait à l’exécution des Prestations, peut
                récupérer son véhicule via le Service ou directement auprès du
                PDV. Si le Client souhaite récupérer son véhicule via le
                Service, il devra néanmoins prendre contact avec le PDV, afin
                qu’il lui soit adressé un code de retrait.<br />
                2.2.4- Avis de fin de travaux et facturation – Restitution du
                véhicule<br />
                Une fois les Prestations réalisées, le PDV adresse au Client un
                avis de fin de travaux via SMS au numéro de téléphone mobile
                renseigné par le Client.<br />
                A ce stade, le Client peut soit récupérer en PDV son véhicule
                pendant les heures d’ouverture de ce dernier soit opter pour
                bénéficier du Service.<br />
                Dans le cas où le Client souhaiterait bénéficier du Service, il
                dispose d’un délai de 24 heures à compter de la réception dudit
                avis de fin de travaux pour procéder au paiement de la
                Prestation et récupérer son véhicule.<br />
                <br />
                Dans cet avis de fin de travaux, il est indiqué au Client :<br />
                La date et l’heure à partir de laquelle le véhicule est tenu à
                sa disposition, dans la Zone de Réception. Si le PDV a été dans
                l’impossibilité de garer le véhicule sur la Zone de Réception,
                il précise dans l’avis<br />
                l’endroit où est garé le véhicule ;<br />
                le lien URL de paiement des Prestations ;<br />
                Le rappel du délai maximum de 24 heures, à compter de la
                réception de l’avis de fin de travaux, pour récupérer son
                véhicule.<br />
                A défaut de respecter ce délai, le PDV pourra se prévaloir de
                l’application de l’indemnité d’encombrement dans les conditions
                de l’article 9 des CGR.<br />
                2.2.5 - Paiement à partir du lien sécurisé URL<br />
                Une fois que le paiement des Prestations, à partir du lien
                sécurisé URL envoyé au Client, a bien été effectué, il est
                délivré au Client un code à 6 chiffres, dit code de retrait. Ce
                code de retrait permet d’acter que le paiement a bien été
                effectué pour les Prestations et est nécessaire pour récupérer
                les clés du véhicule voire le certificat d’immatriculation si ce
                dernier a été remis au PDV directement ou indirectement via la
                Borne. Il est précisé que dans le cas où le Client a utilisé le
                Service intégralement, le code de dépôt et le code de retrait
                sont différents.<br />
                Il est bien précisé qu’il ne peut y avoir restitution du
                véhicule sans paiement préalable de la Prestation exécutée.<br />
                2.2.6 – Restitution du véhicule<br />
                En complément des dispositions relatives à la restitution du
                véhicule figurant dans les CGR, le prix total de la Prestation
                doit être intégralement payé à la fin des Prestations. Le
                paiement intégral constitue une condition essentielle et
                déterminante à la restitution du Véhicule par le PDV,
                directement ou indirectement via la Borne.<br />
                Le Client qui a procédé au paiement conformément aux termes de
                l’article 2.2.5 ci-dessus récupère son véhicule, sur la Zone de
                Réception, quand il le souhaite, pour autant qu’il respecte le
                délai imparti de 24 heures à compter de l’avis de restitution du
                véhicule, tel que précisé dans l’article 2.2.4 ci-dessus.<br />
                Pour récupérer ses clés, voire le certificat d’immatriculation
                dès lors que ce dernier a été remis au PDV, dans le cadre du
                Service, via la Borne, le Client doit saisir sur la Borne, le
                code de retrait à 6 (six) chiffres qui lui a été délivré lors du
                paiement de la Prestation. De la même façon que lors du dépôt du
                véhicule, la Borne offre la possibilité au Client de récupérer
                le code de retrait, en cas de perte de ce dernier.<br />
                Il est précisé que dans tous les cas, la facture des Prestations
                aura été soit placée dans le véhicule, soit envoyée par mail au
                Client, par le PDV, à l’adresse mail renseignée par ce
                dernier.<br />
                ARTICLE 3 - PRIX - PAIEMENT<br />
                3.1 - Prix et modalités de paiement<br />
                3.1.1 – Prix<br />
                Le prix de base des Prestations figure en PDV, voire le cas
                échéant, sur le Site Internet. Le prix définitif, est annoncé au
                Client, après état des lieux du véhicule, par le PDV,
                conformément aux termes de l’article 2.2.3 ci-dessus.<br />
                Dans ce cadre, il est rappelé que le Service associé aux
                Prestations est gratuit.<br />
                Le prix des Prestations est exprimé en euros toutes taxes
                comprises, selon les taux de taxe<br />
                <br />
                sur la valeur ajoutée et des taxes en vigueur en France, au jour
                de la conclusion de la Commande de Travaux.<br />
                3.1.2 – Paiement<br />
                Le Client s’engage à régler la facture de la commande des
                Prestations associées au Service, au comptant, via lien sécurisé
                URL, communiqué au Client. Aucun escompte ou délai de paiement
                n’est accordé.<br />
                Les cartes bancaires utilisables pour effectuer le règlement
                sont les suivantes : CB, Visa, Mastercard, Amex. Le paiement de
                la commande à partir du lien sécurisé URL est effectué dans les
                conditions suivantes :<br />
                Le paiement intervient à travers la société SCORE & SECURE
                PAYEMENT, S.A.S au capital de 200 000 euros, dont le siège
                social est situé au 25, rue Louis Le Grand, 75002 Paris,
                immatriculée au registre du commerce et des sociétés de Paris,
                sous le n°830 459 053, code interbancaire 13168N (ci-après
                dénommée « SSP ») agissant en sa qualité d’agent mandaté par
                l’établissement de paiement OONEX, agrée par la Banque Nationale
                de Belgique depuis le 23 aout 2016, société anonyme de droit
                belge, dont le siège social est sis Avenue Louise 367, 1050
                Bruxelles, Belgique, enregistrée sous le numéro 0849.185.510.<br />
                En cas de question sur les modalités de paiement, le Client peut
                prendre contact avec SSP à l’adresse suivante :
                support@safedebit.fr.<br />
                Aux fins de protection contre la fraude, un contrôle pourra être
                effectué. Dans ce cadre, le Client pourra être contacté en vue
                de justifier de son identité.<br />
                La sécurisation du paiement est assurée par SSP. Le service
                intègre la norme de sécurité SSL. Une demande d’authentification
                du Client au moment du paiement pourra être effectuée. Aucune
                donnée de paiement confidentielle (numéro de carte, date
                d’expiration ou code à 3 chiffres) n’est stockée. Ces données
                sont directement transmises cryptées au prestataire de paiement
                de SSP qui dispose de toutes les autorisations nécessaires à
                leur traitement.<br />
                - Les données transmises seront utilisées pour effectuer le
                paiement et pourront également être utilisées par SSP, dans le
                respect des dispositions légales sur la protection des données
                personnelles, à des fins commerciales ou marketing. Le
                consentement du Client concernant l’utilisation de ces données
                par SSP, est obtenu via la case à cocher présente dans le
                parcours Client relatif au paiement.<br />
                Les dispositions des CGR (articles 10 et/ou 13) relatives à la
                vente aux enchères et au droit de rétention du PDV sont
                pleinement applicables, en cas de défaut de paiement.<br />
                Conformément à l’arrêté du 3 octobre 1983, modifié, toute
                prestation de service doit faire l’objet, dès qu’elle a été
                rendue et en tout état de cause avant paiement du prix, de la
                délivrance d’une note lorsque le prix de la prestation est
                supérieur ou égal à 25 € (TVA comprise). Pour les prestations de
                service dont le prix est inférieur à 25 € (TVA comprise), la
                délivrance d’une note est facultative, mais celle-ci doit être
                remise au Client s’il la demande.<br />
                Pour toute commande de la Prestation associée au Service par un
                Client professionnel, et en application de l’article L441-6 du
                Code de commerce, il est expressément convenu qu’en cas de
                retard de paiement du prix par le Client, le PDV pourra lui
                appliquer des pénalités calculées sur les sommes exigibles et
                non payées à bonne date au taux minimum prévu par la loi, égal à
                trois (3) fois le taux d’intérêt légal en vigueur, à compter de
                la date d’exigibilité desdites sommes. Par ailleurs, une
                indemnité forfaitaire pour frais de recouvrement d’un montant
                minimum de quarante euros (40 €) pourra être appliquée au Client
                professionnel.<br />
                <br />
                ARTICLE 4 - DONNEES A CARACTERE PERSONNEL<br />
                A titre liminaire, il est rappelé que le véhicule objet de la
                Prestation associée au Service, est équipé de systèmes
                informatiques et électroniques permettant la transmission
                d’informations techniques sur le fonctionnement et l’utilisation
                du véhicule telles que l’état de ses organes, ses niveaux de
                fluides, son kilométrage, des journaux d’événements sur ses
                composants et de réaliser et délivrer ainsi, notamment des
                diagnostics et des alertes mécaniques ou de sécurité. Afin
                d’assurer son bon fonctionnement, les systèmes informatiques et
                électroniques du véhicule peuvent être mis à jour à distance.
                Tant lors de la réception du véhicule que la réalisation de
                l’estimation des travaux, le véhicule pourra être filmé par le
                PDV à des fins de transparence de l’information du Client.<br />
                Les informations collectées en PDV lors de la prise de
                rendez-vous, lors de la réception du véhicule en cas notamment
                de réalisation d’une vidéo au moyen de l’outil Vidéo-check et de
                l’établissement de la Commande de travaux, sur les vidéo de
                diagnostiques et par le véhicule sont nécessaires à la société
                IPSIP GROUP, sis Multi parc du Salaison, 145 rue de la marbrerie
                34740 VENDARGUES, son réseau commercial, ses réparateurs, toute
                société pouvant rendre un service pour le compte de IPSIP et/ou
                toute société appartenant au même Groupe que IPSIP, pour le
                traitement de la demande du Client.<br />
                Pour les finalités ci-dessus, IPSIP GROUP peut avoir besoin de
                transmettre les informations susvisées dans d’autres pays
                membres ou non de l’Union Européenne garantissant un niveau
                adéquat de protection des données. Dans ce cas, toutes les
                mesures nécessaires sont prises pour vous éviter tout
                préjudice.<br />
                Les données collectées par IPSIP GROUP sont conservées pendant
                10 ans à compter de la date de la fin de la relation
                contractuelle, comme stipulée dans la Déclaration de
                Confidentialité que le PDV envoie au Client en accompagnement de
                la Commande de Travaux.<br />
                Conformément au Règlement Général sur la protection des données
                n°2016/679 du 27 avril 2016 et à la Loi informatique et Libertés
                n°78-17 du 6 janvier 1978, telle qu’amendée, le Client dispose
                d’un droit d’accès et de rectification des informations
                susvisées ainsi que d'un droit d'opposition à leur traitement.
                Le Client peut exercer ces droits sur simple demande auprès de
                IPSIP GROUP : Multi parc du Salaison, 145 rue de la marbrerie
                34740 VENDARGUES, Contact Siège : +33 1 83 626 378 , ou par
                Internet à l’adresse suivante: https://www.ipsip.eu<br />
                Le Client peut également adresser à IPSIP GROUP des directives
                relatives au sort de ses informations après son décès. De même,
                en application de l’article L223-2 du Code de la consommation,
                le Client est informé qu’il peut s’opposer à tout moment à être
                démarché par téléphone, en s’inscrivant gratuitement sur le site
                www.bloctel.gouv.fr.<br />
                ARTICLE 5 - RESPONSABILITE - ASSURANCES<br />
                5.1 – Responsabilité - Exclusions de responsabilité<br />
                Il est rappelé que la garde :<br />
                des clés voire, le cas échéant, du certificat d’immatriculation
                n’est transférée au PDV qu’après acceptation par le Client des
                CG24/7 sur la Borne, de façon à permettre à cette dernière de se
                verrouiller<br />
                du véhicule n’est transféré au PDV qu’à partir de la réception
                du véhicule telle que définie dans l’article 2.2.3 ci-dessus et
                ce jusqu’à la notification par le PDV au Client de la fin
                d’exécution des travaux, conformément aux termes de l’article
                2.2.4.<br />
                Le PDV n’est responsable que des dégradations et/ou incidents
                intervenus sur le véhicule pendant la période durant laquelle le
                véhicule est placé sous sa garde.<br />
                Indisponibilité ou retard: le PDV ne pourra en aucun cas être
                tenu responsable dans les cas suivants<br />
                <br />
                :<br />
                en cas de retard dans la prise en charge du Véhicule dû à toute
                réponse tardive du Client quant aux Prestations, à défaut
                d’accord sur la Commande de Travaux et/ou absence de remise des
                clés du véhicule et, le cas échéant, du certificat
                d’immatriculation du véhicule...).<br />
                en cas de délai de maintenance ou réparation du véhicule plus
                long que prévu, ou de<br />
                retard non imputable au PDV. Dans ce cas, le Client sera tenu
                informé.<br />
                5.1.1- Limitations de responsabilité<br />
                Dans l’hypothèse où la responsabilité du PDV pourrait être
                retenue, le Client reconnaît et accepte que le PDV ne pourra en
                aucun cas se voir demander la réparation de dommages indirects
                (notamment pertes de données, préjudice commercial, perte de
                chiffre d’affaires ou de bénéfice, perte de commande ou de
                clientèle, perte d’une chance, etc.) subis par le Client et/ou
                utilisateurs du véhicule, quelles qu’en soient la nature et la
                cause. La responsabilité du PDV pour des dommages directs causés
                par lui-même sera, dans tous les cas, limitée aux plafonds
                d’indemnisation fixés par son assurance Responsabilité Civile
                (telle que décrite dans les CGSR), sans que le Client puisse
                réclamer quelque autre réparation de ce chef.<br />
                5.1.2- Force majeure<br />
                Plus généralement, la responsabilité du PDV ne saurait être
                engagée pour tout manquement dans l’exécution de ses obligations
                tenant à un cas de force majeure, ou à un cas fortuit
                indépendant de sa volonté, ou au fait du Client, ou à toutes
                raisons extérieures au PDV. Aucun recours ne pourra être engagé
                contre le PDV.<br />
                5.2 – Assurances<br />
                5.2.1 Assurance du Client<br />
                Le Client déclare avoir souscrit auprès d’une compagnie
                d’assurance notoirement solvable, une police d’assurance
                couvrant le véhicule en Responsabilité civile pendant toute la
                durée du présent Contrat. Le Client doit être en mesure de la
                produire à première demande du PDV.<br />
                5.2.2 – Assurance du PDV<br />
                Le PDV déclare avoir souscrit auprès d’une compagnie notoirement
                solvable, une police d’assurance garantissant les conséquences
                pécuniaires de la responsabilité civile qu’il pourrait encourir,
                du fait des dommages de toute nature causés par lui ou par son
                personnel à l’autre Partie et/ou aux tiers, du fait ou à
                l’occasion de l’exécution du présent Contrat<br />
                ARTICLE 6 - ABSENCE DE DROIT A RETRACTATION<br />
                En application des dispositions du Code de la Consommation, il
                n’y a pas de droit à rétractation.<br />
                ARTICLE 7 - SUIVI DE COMMANDE – QUESTIONS – RÉCLAMATIONS<br />
                Pour toute question ou réclamation relative aux Prestations
                et/ou au Service associé aux Prestations, le Client doit
                s’adresser au PDV, dont les coordonnées sont indiquées dans
                l’email envoyé par le PDV ou sur la Commande de Travaux.<br />
                Le Client peut joindre le service Relations Clientèle de la
                marque du PDV pour toute demande d’information ou réclamation
                portant sur les CG24/7.<br />
                Pour AUTOMOBILES CITROËN :<br />
                Par téléphone au 09 69 39 18 18, du lundi au vendredi de 8h à
                19h et le samedi de 9h à 13h (sauf jours fériés).<br />
                Par courrier à l’adresse suivante : AUTOMOBILES CITROËN, Service
                Relations<br />
                <br />
                Clientèle, Case YT227, 2-10 boulevard de l'Europe, 78300
                POISSY<br />
                Par internet sur le site https://citroen-fr-fr.custhelp.com/ ou
                www.citroen.com<br />
                Pour AUTOMOBILES PEUGEOT :<br />
                Par téléphone au 09 70 80 91 20 (prix d’un appel local depuis un
                poste fixe) du lundi au vendredi de 8h à 19h et le samedi de 9h
                à 13h (sauf jours fériés).<br />
                Par courrier à l’adresse suivante : Automobiles Peugeot –
                Service Relations Clientèle – Case<br />
                YT 227 – 2/10 bd de l’Europe 78300 POISSY.<br />
                Par internet sur le site https://peugeot-fr-fr.custhelp.com/ ou
                www.peugeot.com<br />
                Pour DS AUTOMOBILES :<br />
                Par téléphone au 09 69 32 19 55 (prix d’un appel local depuis un
                poste fixe) du lundi au vendredi de 8h à 19h et le samedi de 9h
                à 13h (sauf jours fériés).<br />
                Par courrier à l’adresse suivante : DS Automobiles – 2/10 bd de
                l’Europe 78300 POISSY. Par internet sur le site
                www.dsautomobiles.com<br />
                Pour OPEL :<br />
                Par téléphone au 08 21 98 00 06 (prix d’un appel local depuis un
                poste fixe) du lundi au vendredi de 8h à 19h et le samedi de 9h
                à 13h (sauf jours fériés).<br />
                Par courrier à l’adresse suivante : OPEL France Service
                Relations Clientèle, 2-10 Boulevard de l’Europe - 78300
                Poissy<br />
                Par internet sur le site www.opel.fr<br />
                ARTICLE 8 - VALIDITE DES CONDITIONS GÉNÉRALES DE VENTE ET DES
                PRIX<br />
                Les Conditions Générales de Service 24/7 (CG24/7), les
                Prestations, le Service associé aux Prestations et les prix
                peuvent être modifiés à tout moment sans préavis par le PDV. Les
                modifications entrent en vigueur dès leur mise à jour ou leur
                modification sur le Site Internet.<br />
                Toute commande d’une Prestation associée au Service est régie
                par les Conditions Générales 24/7 (CG24/7) et les prix diffusés
                dans l’email de confirmation de la commande envoyé au Client.<br />
                ARTICLE 9 - RÉSEAU INTERNET - BORNE<br />
                Le PDV ne saurait en aucune circonstance être tenu responsable,
                sans que cette liste soit limitative, de la transmission et/ou
                de la réception de toute donnée et/ou information sur internet ;
                de tout dysfonctionnement du réseau internet ou technique
                empêchant l’accès au Site Internet et/ou à la Borne et/ou le bon
                déroulement/fonctionnement de la commande du Client ; d’une
                défaillance de tout matériel de réception ou des lignes de
                communication ; de perte de tout courrier papier ou électronique
                et, plus généralement, de perte de toute donnée ; des problèmes
                d’acheminement ; du fonctionnement de tout logiciel ; de toute
                défaillance technique et matérielle ayant empêché ou limité la
                possibilité du Client de commander les Prestations associées au
                Service et/ou d’en bénéficier. En cas de dysfonctionnement
                technique, le PDV se réserve le droit, s’il y a lieu,
                d’invalider et/ou d’annuler la commande du Client, intervenue au
                cours dudit dysfonctionnement. Aucune réclamation ne sera
                acceptée de ce fait.<br />
                ARTICLE 10 - DROIT APPLICABLE – LANGUE DU CONTRAT<br />
                En cas de litige relatif aux Conditions Générales de Self
                Réception et au Site Internet, seuls les tribunaux de Paris
                seront compétents, sauf disposition légale impérative contraire.
                Ils statueront exclusivement en droit interne français à
                l’exclusion des règles en matière de conflits de loi et sans
                préjudice des dispositions légales du pays de résidence du
                Client auxquelles il ne peut être dérogé.<br />
                <br />
                Si le Client est un consommateur, il pourra engager une
                procédure devant la juridiction de son choix ou recourir à une
                médiation conventionnelle ou à tout autre mode alternatif de
                règlement des conflits.<br />
                Les présentes sont proposées en langue française. Dans le cas où
                les Conditions Générales de Self Réception seraient traduites en
                une ou plusieurs langues étrangères, seule la version française
                fera foi.<br />
                Si une ou plusieurs stipulations des Conditions Générales de
                Self Réception sont tenues pour non valides ou déclarées comme
                telles en application d’une loi, d’un règlement ou à la suite
                d’une décision définitive d’une juridiction compétente, elles
                seront réputées non écrites et les autres stipulations
                demeureront en vigueur.<br />
                CG24/7 du 12 novembre 2020<br />
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialog = false">
                  {{ $t("main.close") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="2" />
      </v-row>
      <v-row>
        <v-col cols="2" />
        <v-col cols="8"
          ><p v-if="!mileageValid" style="color: #ff0000">
            <strong>{{ $t("vehicle.invalidMileage") }}</strong>
          </p>
          <p v-if="!cgvAccepted" style="color: #ff0000">
            <strong>{{ $t("vehicle.cgvNotAccepted") }}</strong>
          </p>
        </v-col>
        <v-col cols="2" />
      </v-row>
    </v-container>
    <v-footer
      class="justify-center"
      style="position: absolute; bottom: 50px; width: 100%"
    >
      <SimpleKeyboard
        v-show="editMileage"
        :input="input"
        @onChange="onChange"
      />
    </v-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import SimpleKeyboard from "@/components/SimpleKeyboard";
import axios from "axios";
export default {
  name: "VehicleInfo",
  components: {
    SimpleKeyboard,
  },
  data() {
    return {
      mileage: this.$store.state.vehicleMileage,
      input: "",
      editMileage: false,
      mileageValid: false,
      cgvAccepted: false,
      dialog: false,
    };
  },
  mounted() {
    if (this.$store.state.apptId == 0) this.$router.push("/");
    this.validate();
  },
  methods: {
    onChange(input) {
      let cb_conf = this.$store.state.callback;
      if (this.$store.state.hasMotors) axios.get("http://localhost:3000/front");
      else {
        if (this.$store.state.timeout !== "not yet")
          window.clearTimeout(this.$store.state.timeout);
        let timeout = window.setTimeout(() => {
          window.location.href = cb_conf;
        }, process.env.VUE_APP_NOMOTOR_TIMEOUT);
        this.$store.commit({ type: "timeout", timeout: timeout });
      }
      console.log("reset timeout");

      if (this.editMileage) {
        this.mileage = input;
      }
    },
    onInputChange(input) {
      let cb_conf = this.$store.state.callback;
      if (this.$store.state.hasMotors) axios.get("http://localhost:3000/front");
      else {
        if (this.$store.state.timeout !== "not yet")
          window.clearTimeout(this.$store.state.timeout);
        let timeout = window.setTimeout(() => {
          window.location.href = cb_conf;
        }, process.env.VUE_APP_NOMOTOR_TIMEOUT);
        this.$store.commit({ type: "timeout", timeout: timeout });
      }
      this.input = input.target.value;
    },
    commitMileage() {
      if (!this.editMail) {
        this.$store.commit("vehicleMileage", { mileage: this.mileage });
        this.validate();
      }
    },
    validate() {
      let regMileage = /^[0-9]+$/;
      this.mileageValid =
        this.email == "" ? false : regMileage.test(this.mileage) ? true : false;
      if (this.mileageValid && this.cgvAccepted) {
        this.$store.commit("enableNext");
      } else {
        this.$store.commit("disableNext");
      }
    },
    toggleCgv() {
      if (this.cgvAccepted) {
        this.$store.commit("cgvAccepted");
        this.$store.commit("enableNext");
      } else {
        this.$store.commit("cgvNotAccepted");
        this.$store.commit("disableNext");
      }
    },
  },
};
</script>
